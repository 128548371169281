<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Usuarios</span>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center">
      <v-col cols="8">
        <v-text-field
          v-model="userListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col style="align-self: center; text-align: right;" cols="4">
        <v-btn
          depressed
          color="default-btn primary"
          @click="createUserDialog = true, isCreateOrUpdate = true, passwordDisabled = false "
        >
          Agregar usuario
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
          Para restringir el acceso de un usuario a la plataforma,
          podrá quitar el check de la opción "activo" que aparece en el detalle de cada usuario.
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <h5>Nombre de usuario</h5>
      </v-col>
      <v-col cols="3">
        <h5>Nombre</h5>
      </v-col>
      <v-col cols="4">
        <h5>Email</h5>
      </v-col>
      <v-col cols="2">
        <h5>País</h5>
      </v-col>
      <v-col cols="1">
        <h5>Acción</h5>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="userList"
      :search="userListSearch"
      loading-text="Cargando"
      :loading="userListLoader"
      no-data-text="No hay usuarios creados"
      :items-per-page="8"
      :footer-props="{itemsPerPageOptions:[8, 16, -1]}"
    >
      <template v-slot:default="props">
          <div v-for="(userItem, index) in props.items" :key="index">
          <v-row :class="index%2 === 0 ? 'backWhite' : 'backGray'">
            <v-col cols="2">
              <span> {{ userItem.username }} </span>
            </v-col>
            <v-col cols="3">
              <span> {{ userItem.name }} </span>
            </v-col>
            <v-col cols="4">
              <span> {{ userItem.email }} </span>
            </v-col>
            <v-col cols="2">
              <span> {{ userItem.country }} </span>
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editUser(userItem)"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                    >mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          </div>
      </template>
  </v-data-iterator>
    <v-dialog
      max-width='800px'
      v-model="createUserDialog"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center-dialog centered-dialog__card">
          <v-icon class="mx-2">mdi-account-multiple-outline</v-icon>
          <h4> {{ getCreateOrEdit() }} usuario</h4>
          <v-icon class="mx-2">mdi-account-multiple-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="userForm" v-model="userForm.isValid">
            <v-row no-gutters>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  name="username"
                  label="Nombre de usuario"
                  background-color="white"
                  :disabled="usernameDisabled"
                  v-model="userForm.username"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  :rules="passwordRules"
                  name="password"
                  label="Contraseña"
                  background-color="white"
                  v-model="userForm.password"
                  :append-icon="passwordVisibility ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  @click:append="() => (passwordVisibility = !passwordVisibility)"
                  :disabled="passwordDisabled"
                  hint="Mínimo debe contener un número, una mayúscula y 5 caractéres"
                  ref="passwordRef"
                  autoocomplete="off"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4" v-if="!passwordDisabled">
                <v-text-field
                  class="form-field mt-2"
                  :rules="[(userForm.password === userForm.password2) || 'Las contraseñas deben coincidir.']"
                  name="password"
                  label="Verificar contraseña"
                  background-color="white"
                  v-model="userForm.password2"
                  :append-icon="passwordVisibility2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="passwordVisibility2 ? 'text' : 'password'"
                  :disabled="passwordDisabled"
                  @click:append="() => (passwordVisibility2 = !passwordVisibility2)"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  name="name"
                  label="Nombre"
                  background-color="white"
                  v-model="userForm.name"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  name="lastName"
                  label="Apellido"
                  background-color="white"
                  v-model="userForm.lastName"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-text-field
                  class="form-field mt-2"
                  :rules="emailRules"
                  name="email"
                  label="Email"
                  background-color="white"
                  v-model="userForm.email"
                  type="email"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-select
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  :items="countryList"
                  item-text="name"
                  item-value="id"
                  label="País"
                  background-color="white"
                  v-model="userForm.country"
                />
              </v-col>
              <v-col class="px-2 py-0" cols="4">
                <v-select
                  class="form-field mt-2"
                  :rules="textFieldRules"
                  :items="areaList"
                  item-text="name"
                  item-value="id"
                  label="Area"
                  background-color="white"
                  v-model="userForm.area"
                />
              </v-col>
              <v-col class="px-2 py-0" style="align-self: center;" cols="4" v-if="!isCreateOrUpdate">
                <v-checkbox
                  class="form-field mt-2"
                  label="Activo"
                  background-color="white"
                  v-model="userForm.active"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="center-dialog" cols="12">
                <span class="my-folders-text"> Modificado por {{ userForm.usermod }} el {{ userForm.updated }}</span>
              </v-col>
              <v-col class="center-dialog" cols="12">
                <span class="pointer change-state" @click="passwordDisabled=!passwordDisabled"> Cambiar contraseña </span>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pt-6 mr-3">
              <v-btn
                depressed
                color="default-btn accent"
                @click="closeDialog()"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-6 ml-3">
              <v-btn
                depressed
                color="default-btn primary"
                :disabled="!userForm.isValid && isCreateOrUpdate"
                :loading="createOrEditBtnLoader"
                @click="createOrUpdateUser()"
              >
                {{ getCreateOrEdit() }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="editIconLoader">
      <Loader />
    </div>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Users',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      userList: [],
      userListSearch: '',
      userListLoader: false,
      createUserDialog: false,
      countryCodeDisabled: false,
      userForm: {
        name: '',
        lastName: '',
        username: '',
        password: '',
        password2: '',
        country: '',
        area: '',
        email: '',
        active: false,
        id: '',
        isValid: false
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      emailRules: [
        v => !v || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'El email debe ser válido'
      ],
      passwordRules: [
        v => !!v || 'Este campo es requerido',
        v => (v && v.length >= 5) || 'La contraseña debe tener 5 o más caracteres',
        v => /(?=.*[A-Z])/.test(v) || 'La contraseña debe de contener al menos una mayúscula',
        v => /(?=.*\d)/.test(v) || 'La contraseña debe de contener al menos 1 número'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false,
      passwordVisibility: false,
      passwordVisibility2: false,
      areaList: [],
      countryList: [],
      editIconLoader: false,
      usernameDisabled: false,
      passwordDisabled: false
    }
  },

  created () {
    this.userListLoader = true
    this.getUsers()
    this.getAreas()
    this.getCountries()
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  methods: {
    ...mapActions('administration', ['getAdminUsers', 'getAdminUser', 'updateAdminUsers',
      'addAdminUser', 'getAdminAreas', 'getAdminCountries']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getUsers () {
      this.getAdminUsers()
        .then(response => {
          this.userListLoader = false
          this.userList = response.data
          this.closeDialog()
        })
        .catch(() => {
          this.userListLoader = false
        })
    },

    editUser (item) {
      this.isCreateOrUpdate = false
      this.editIconLoader = true
      this.usernameDisabled = true
      const request = {
        id: item.id
      }
      this.getAdminUser(request)
        .then(response => {
          const auxForm = this.userForm
          Object.keys(response.data).map(function (key, index) {
            if (key !== 'lastLogin') {
              auxForm[key] = response.data[key]
            }
          })
          auxForm.password = ''
          auxForm.password2 = ''
          this.passwordDisabled = true
          this.userForm = auxForm
          this.createUserDialog = true
          this.editIconLoader = false
        })
        .then(() => {
          this.editIconLoader = false
        })
    },

    createOrUpdateUser () {
      this.createOrEditBtnLoader = true
      if (this.isCreateOrUpdate) {
        const request = {
          name: this.userForm.name,
          lastName: this.userForm.lastName,
          username: this.userForm.username,
          password: this.userForm.password,
          country: this.userForm.country,
          area: this.userForm.area,
          email: this.userForm.email
        }

        this.addAdminUser(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getUsers()
            this.setSnackbar({
              text: 'Usuario registrado correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
          .catch(error => {
            this.createOrEditBtnLoader = false
            const data = error.response.data
            if (data.email) {
              this.setSnackbar({
                text: data.email.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else if (data.username) {
              this.setSnackbar({
                text: data.username.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else if (data.message) {
              this.setSnackbar({
                text: data.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else {
              this.setSnackbar({
                text: 'No se pudo crear el usuario',
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            }
          })
      } else {
        const request = {}
        const auxForm = this.userForm

        Object.keys(auxForm).map(function (key, index) {
          if (key !== 'isValid' && auxForm[key] !== '' && auxForm[key] !== '') {
            request[key] = auxForm[key]
          }
        })

        this.updateAdminUsers(request)
          .then(() => {
            this.createOrEditBtnLoader = false
            this.getUsers()
            this.setSnackbar({
              text: 'Usuario actualizado correctamente',
              timeout: 5000,
              showing: true,
              color: colors.success
            })
          })
          .catch(error => {
            this.createOrEditBtnLoader = false
            const data = error.response.data
            if (data.email) {
              this.setSnackbar({
                text: data.email.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else if (data.message) {
              this.setSnackbar({
                text: data.message,
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            } else {
              this.setSnackbar({
                text: 'No se pudo actualizar el usuario',
                timeout: 5000,
                showing: true,
                color: colors.error
              })
            }
          })
      }
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    getAreas () {
      this.getAdminAreas()
        .then(response => {
          this.areaList = response.data
        })
    },

    getCountries () {
      this.getAdminCountries()
        .then(response => {
          this.countryList = response.data
        })
    },

    closeDialog () {
      this.createUserDialog = false
      this.countryCodeDisabled = false
      this.$refs.userForm.resetValidation()
      this.$refs.passwordRef.reset()
      this.passwordVisibility = false
      this.passwordVisibility2 = false
      this.usernameDisabled = false
      this.userForm = {
        name: '',
        lastName: '',
        username: '',
        password: '',
        password2: '',
        country: '',
        area: '',
        email: '',
        active: false,
        id: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

.change-state {
  color: #435CC8;
  cursor: pointer;
}
.change-state:active {
    color: rgb(189, 189, 255);
    cursor: wait;
}

.pointer {
  cursor: pointer;
}
</style>
